<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Cabang</h5>
      </template>
      <!-- Filter -->
      <div class="search-wrapper d-flex align-items-center justify-content-between">
        <b-form-group label="Pencarian" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
          <div class="d-flex search-input-wrapper">
            <b-form-input class="ml-2 mr-2" v-model="filter.keyword" name="filterTableCabang" id="filterInputCabang"
              type="search" placeholder="nama atau alamat" @keyup="handleChangeSearch"></b-form-input>
            <b-button variant="primary" @click="onReset()"><i class="fa fa-undo"></i> Atur Pencarian Ulang</b-button>
          </div>
        </b-form-group>
        <div>
          <b-button class="mb-3 mr-2" variant="pgiBtn" name="cabangAddBtn" @click="$bvModal.show('m-create-cabang')"><i
              class="fa fa-plus fs-6"></i> Tambah Data</b-button>
        </div>
      </div>
      <!-- Table -->
      <b-table bordered hover show-empty :busy="isBusy" :items="cabang" :fields="fields" :perPage="perPage" class="mb-3">
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner small class="align-middle" variant="secondary"></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span>
          </div>
        </template>

        <template v-slot:cell(is_active)="row">
          <div v-if="row.item.is_active == 1">
            <b-badge pill variant="success">Aktif</b-badge>
          </div>
          <div v-else>
            <b-badge pill variant="danger">Tidak Aktif</b-badge>
          </div>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button size="sm" class="ml-1 mr-1" variant="warning" @click="handleRedirectEdit(`${row.item.id}`)">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </b-button>
            <!-- <b-button size="sm" class="ml-1 mr-1" variant="warning" @click="showModalEdit(`${row.item.id}`)">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </b-button> -->

            <b-button size="sm" class="ml-1 mr-1" variant="pgiBtn" @click="onDetailCabang(`${row.item.id}`)">
              <i class="fa fa-info mr-1 ml-1" aria-hidden="true"></i>
            </b-button>
          </div>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal id="m-create-cabang" class="modal-lg" size="lg" title="Cabang" ref="my-modal-cabang" no-close-on-backdrop
      no-close-on-esc hide-footer hide-header>
      <b-form @submit.prevent="onConfirm">
        <b-row>
          <!-- input kode cabang -->
          <b-col lg="4">
            <b-form-group id="fg-create-kode-cabang" label="Kode Cabang" label-for="input-kode-cabang" class="required">
              <b-form-input id="input-kode-cabang" type="text" v-model.trim="form.kode"
                placeholder="Masukkan kode cabang"></b-form-input>
              <span v-if="!$v.form.kode.required && $v.form.kode.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- input nama cabang -->
          <b-col lg="4">
            <b-form-group id="fg-create-name-cabang" label="Nama Cabang" label-for="input-nama-cabang" class="required">
              <b-form-input id="input-nama-cabang" type="text" v-model.trim="form.name"
                placeholder="Masukkan nama cabang"></b-form-input>
              <span v-if="!$v.form.name.required && $v.form.name.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input no telepon -->
          <b-col lg="4">
            <b-form-group id="fg-create-telepon-cabang" label="No. Telepon Cabang" label-for="input-telepon-cabang"
              class="required">
              <b-form-input id="input-telepon-cabang" type="text" v-model.trim="form.telepon"
                placeholder="Contoh: 021754XXXX"></b-form-input>
              <span v-if="!$v.form.telepon.required && $v.form.telepon.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input no hp -->
          <b-col lg="4">
            <b-form-group id="fg-create-hp-cabang" label="No. HP Cabang" label-for="input-hp-cabang" class="required">
              <b-form-input id="input-hp-cabang" type="text" v-model.trim="form.hp"
                placeholder="Contoh: 08131234XXXX"></b-form-input>
              <span v-if="!$v.form.hp.required && $v.form.hp.$dirty" class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- input alamat -->
          <b-col md="12">
            <b-form-group id="fg-create-alamat-cabang" label="Alamat Cabang" label-for="input-alamat-cabang"
              class="required">
              <b-form-textarea id="input-alamat-cabang" type="text" rows="4" v-model.trim="form.alamat"
                placeholder="Masukkan alamat cabang"></b-form-textarea>
              <span v-if="!$v.form.alamat.required && $v.form.alamat.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input kabupaten/kota -->
          <b-col lg="4">
            <b-form-group label="Kabupaten">
              <multiselect v-model="form.kabupaten_kota_id" :options="kabupatens" :multiple="false"
                :close-on-select="true" :clear-on-select="false" :preserve-search="true" label="name" track-by="name"
                :preselect-first="true">
              </multiselect>
              <span v-if="!$v.form.kabupaten_kota_id.required && $v.form.kabupaten_kota_id.$dirty"
                class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input kas awal -->
          <b-col lg="4">
            <b-form-group id="fg-create-kas-awal-cabang" label="Kas Awal Cabang" label-for="input-kas-awal-cabang"
              class="required">
              <b-input-group prepend="Rp." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-kas-awal-cabang" type="text" v-model="form.kas_awal"
                  placeholder="Masukkan kas awal"></b-form-input>
              </b-input-group>
              <span v-if="!$v.form.kas_awal.required && $v.form.kas_awal.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input kas berjalan -->
          <b-col lg="4">
            <b-form-group id="fg-create-kas-berjalan-cabang" label="Kas Berjalan Cabang"
              label-for="input-kas-berjalan-cabang" class="required">
              <b-input-group prepend="Rp." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="input-kas-berjalan-cabang" type="text" v-model.trim="form.cash_in_hand_berjalan"
                  placeholder="Masukkan kas Berjalan"></b-form-input>
              </b-input-group>
              <span v-if="!$v.form.cash_in_hand_berjalan.required &&
                $v.form.cash_in_hand_berjalan.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- input kepala unit -->
          <b-col lg="4">
            <b-form-group id="fg-create-kepala-unit-cabang" label="Kepala Unit Cabang"
              label-for="input-kepala-unit-cabang">
              <multiselect @search-change="searchUserKanit" v-model="form.kepala_unit_id" :options="resultUsers"
                :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                placeholder="Cari User (min 3 karakter)" label="name" track-by="name" :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>

          <!-- input kepala cabang senior -->
          <b-col lg="4">
            <b-form-group id="fg-create-kepala-cabang-senior" label="Kepala Cabang Senior"
              label-for="input-kepala-cabang-senior">
              <multiselect @search-change="searchUserKacabSenior" v-model="form.kepala_cabang_senior_id"
                :options="resultUsers" :multiple="false" :close-on-select="true" :clear-on-select="false"
                :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name" track-by="name"
                :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>

          <!-- input kepala cabang -->
          <b-col lg="4">
            <b-form-group id="fg-create-kepala-cabang" label="Kepala Cabang" label-for="input-kepala-cabang">
              <multiselect @search-change="searchUserKacab" v-model="form.kepala_cabang_id" :options="resultUsers"
                :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                placeholder="Cari User (min 3 karakter)" label="name" track-by="name" :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- input ip address -->
          <b-col lg="4">
            <b-form-group id="fg-create-ip-cabang" label="IP Address Cabang" label-for="input-ip-cabang" class="required">
              <b-form-input id="input-ip-cabang" type="text" v-model.trim="form.ip_address"
                placeholder="Masukkan ip address cabang"></b-form-input>
              <span v-if="!$v.form.ip_address.required && $v.form.ip_address.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input latitude -->
          <b-col lg="4">
            <b-form-group id="fg-create-latitude-cabang" label="Latitude Cabang" label-for="input-latitude-cabang"
              class="required">
              <b-form-input id="input-latitude-cabang" type="text" v-model.trim="form.latitude"
                placeholder="Masukkan latitude cabang"></b-form-input>
              <span v-if="!$v.form.latitude.required && $v.form.latitude.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- input longtitude -->
          <b-col lg="4">
            <b-form-group id="fg-create-longtitude-cabang" label="Longtitude Cabang" label-for="input-longtitude-cabang"
              class="required">
              <b-form-input id="input-longtitude-cabang" type="text" v-model.trim="form.longitude"
                placeholder="Masukkan longtitude cabang"></b-form-input>
              <span v-if="!$v.form.longitude.required && $v.form.longitude.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal">Batal</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Edit -->
    <b-modal id="m-edit-cabang" class="modal-lg" size="lg" title="Cabang" ref="my-modal-cabang" no-close-on-backdrop
      no-close-on-esc hide-footer hide-header>
      <b-form @submit.prevent="onConfirmEdit">
        <b-row>
          <!-- edit kode cabang -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kode-cabang" label="Kode Cabang" label-for="edit-kode-cabang" class="required">
              <b-form-input id="edit-kode-cabang" type="text" v-model.trim="formEdit.kode"
                placeholder="Masukkan kode cabang"></b-form-input>
              <span v-if="!$v.formEdit.kode.required && $v.formEdit.kode.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group id="fg-edit-status-cabang" label="Status Cabang" label-for="edit-status-cabang">
              <b-form-select size="md" required="required" v-model="formEdit.is_active" :plain="false">
                <template v-slot:first>
                  <b-form-select-option v-if="formEdit.is_active == 1"
                    :value="formEdit.is_active">Aktif</b-form-select-option>
                </template>

                <b-form-select-option value="null">
                  Tidak Aktif
                </b-form-select-option>
                <b-form-select-option value="1"> Aktif </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- input nama cabang -->
          <b-col lg="4">
            <b-form-group id="fg-edit-name-cabang" label="Nama Cabang" label-for="edit-nama-cabang" class="required">
              <b-form-input id="edit-nama-cabang" type="text" v-model.trim="formEdit.name"
                placeholder="Masukkan nama cabang"></b-form-input>
              <span v-if="!$v.formEdit.name.required && $v.formEdit.name.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit no telepon -->
          <b-col lg="4">
            <b-form-group id="fg-edit-telepon-cabang" label="No. Telepon Cabang" label-for="edit-telepon-cabang"
              class="required">
              <b-form-input id="edit-telepon-cabang" type="text" v-model.trim="formEdit.telepon"
                placeholder="Contoh: 021754XXXX"></b-form-input>
              <span v-if="!$v.formEdit.telepon.required && $v.formEdit.telepon.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit no hp -->
          <b-col lg="4">
            <b-form-group id="fg-edit-hp-cabang" label="No. HP Cabang" label-for="edit-hp-cabang" class="required">
              <b-form-input id="edit-hp-cabang" type="text" v-model.trim="formEdit.hp"
                placeholder="Contoh: 08131234XXXX"></b-form-input>
              <span v-if="!$v.formEdit.hp.required && $v.formEdit.hp.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit alamat -->
          <b-col md="12">
            <b-form-group id="fg-edit-alamat-cabang" label="Alamat Cabang" label-for="edit-alamat-cabang"
              class="required">
              <b-form-textarea id="edit-alamat-cabang" type="text" rows="4" v-model.trim="formEdit.alamat"
                placeholder="Masukkan alamat cabang"></b-form-textarea>
              <span v-if="!$v.formEdit.alamat.required && $v.formEdit.alamat.$dirty" class="text-danger">Kolom tidak boleh
                kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit kabupaten/kota -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kabkota-cabang" label="Kabupaten/Kota Cabang" label-for="edit-kabkota-cabang"
              class="required">
              <b-form-select size="md" required="required" v-model="formEdit.kabupaten_kota_id" :plain="false">
                <template v-slot:first>
                  <b-form-select-option disabled :value="null">Pilih Kabupaten/Kota</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in kabupatens" v-bind:key="option.id" :value="option.id">
                  {{ option.name }}
                </b-form-select-option>
              </b-form-select>
              <span v-if="!$v.formEdit.kabupaten_kota_id.required &&
                $v.formEdit.kabupaten_kota_id.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit kas awal -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kas-awal-cabang" label="Kas Awal Cabang" label-for="edit-kas-awal-cabang"
              class="required">
              <b-input-group prepend="Rp." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="edit-kas-awal-cabang" type="text" v-model="formEdit.kas_awal"
                  placeholder="Masukkan kas awal"></b-form-input>
              </b-input-group>
              <span v-if="!$v.formEdit.kas_awal.required && $v.formEdit.kas_awal.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit kas berjalan -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kas-berjalan-cabang" label="Kas Berjalan Cabang"
              label-for="edit-kas-berjalan-cabang" class="required">
              <b-input-group prepend="Rp." class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input id="edit-kas-berjalan-cabang" type="text" v-model.trim="formEdit.cash_in_hand_berjalan"
                  placeholder="Masukkan kas Berjalan"></b-form-input>
              </b-input-group>
              <span v-if="!$v.formEdit.cash_in_hand_berjalan.required &&
                $v.formEdit.cash_in_hand_berjalan.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- edit kepala unit -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kepala-unit-cabang" label="Kepala Unit Cabang" label-for="edit-kepala-unit-cabang"
              class="required">
              <!-- <b-form-select size="md" v-model="formEdit.kepala_unit_id" :plain="false">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Pilih Kepala Unit</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in users" v-bind:key="option.id" :value="option.id">
                  {{ option.name || '' }}
                </b-form-select-option>
              </b-form-select> -->
              <multiselect @select="selectUserKanit" @search-change="searchUserKanit" v-model="formEdit.kepala_unit_id"
                :options="resultUsers" :multiple="false" :close-on-select="true" :clear-on-select="false"
                :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name" track-by="name"
                :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>

          <!-- edit kepala cabang senior -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kepala-cabang-senior" label="Kepala Cabang Senior"
              label-for="edit-kepala-cabang-senior" class="required">
              <!-- <b-form-select size="md" v-model="formEdit.kepala_cabang_senior_id" :plain="false">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Pilih Kepala Cabang Senior</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in users" v-bind:key="option.id" :value="option.id">
                  {{ option.name || '' }}
                </b-form-select-option>
              </b-form-select> -->
              <multiselect @select="selectUserKacab" @search-change="searchUserKacabSenior"
                v-model="formEdit.kepala_cabang_senior_id" :options="resultUsers" :multiple="false"
                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                placeholder="Cari User (min 3 karakter)" label="name" track-by="name" :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>

          <!-- edit kepala cabang -->
          <b-col lg="4">
            <b-form-group id="fg-edit-kepala-cabang" label="Kepala Cabang" label-for="edit-kepala-cabang"
              class="required">
              <!-- <b-form-select size="md" v-model="formEdit.kepala_cabang_id" :plain="false">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Pilih Kepala Cabang</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in users" v-bind:key="option.id" :value="option.id">
                  {{ option.name || '' }}
                </b-form-select-option>
              </b-form-select> -->

              <multiselect @select="selectUserKacabSenior" @search-change="searchUserKacab"
                v-model="formEdit.kepala_cabang_id" :options="resultUsers" :multiple="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Cari User (min 3 karakter)" label="name"
                track-by="name" :preselect-first="true">
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- edit ip address -->
          <b-col lg="4">
            <b-form-group id="fg-edit-ip-cabang" label="IP Address Cabang" label-for="edit-ip-cabang" class="required">
              <b-form-input id="edit-ip-cabang" type="text" v-model="formEdit.ip_address"
                placeholder="Masukkan ip address cabang"></b-form-input>
              <span v-if="!$v.formEdit.ip_address.required &&
                $v.formEdit.ip_address.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit latitude -->
          <b-col lg="4">
            <b-form-group id="fg-edit-latitude-cabang" label="Latitude Cabang" label-for="edit-latitude-cabang"
              class="required">
              <b-form-input id="edit-latitude-cabang" type="text" v-model="formEdit.latitude"
                placeholder="Masukkan latitude cabang"></b-form-input>
              <span v-if="!$v.formEdit.latitude.required && $v.formEdit.latitude.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>

          <!-- edit longtitude -->
          <b-col lg="4">
            <b-form-group id="fg-edit-longtitude-cabang" label="Longtitude Cabang" label-for="edit-longtitude-cabang"
              class="required">
              <b-form-input id="edit-longtitude-cabang" type="text" v-model="formEdit.longitude"
                placeholder="Masukkan longtitude cabang"></b-form-input>
              <span v-if="!$v.formEdit.longitude.required &&
                $v.formEdit.longitude.$dirty
                " class="text-danger">Kolom tidak boleh kosong</span>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-0 mt-md-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan Perubahan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal">Batal</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Detail -->
    <b-modal id="m-detail-cabang" size="xl" title="Detail Cabang" ref="my-modal-cabang" no-close-on-backdrop
      no-close-on-esc hide-footer>
      <LoadingWidget v-if="initStatusDetail != 1" :status="initStatusDetail" @init="showModalDetail" />
      <template v-else>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Kode Cabang
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.kode }}</b></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Nama Cabang
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.name }}</b></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Status Cabang
              </div>
              <div class="col-6 col-lg-8">
                <template v-if="formDetail.is_active === 1">
                  <span class="mr-1">:</span>
                  <b-badge pill variant="success">Aktif</b-badge>
                </template>
                <template v-else>
                  <span class="mr-1">:</span>
                  <b-badge pill variant="danger">Tidak Aktif</b-badge>
                </template>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Kontak Cabang
              </div>
              <div class="col-6 col-lg-8">
                <div class="d-flex">
                  <span class="mr-1">:</span>
                  <div class="row">
                    <div class="col-lg-12">
                      <b>Tlp. {{ formDetail.telepon }}</b>
                    </div>
                    <div class="col-lg-12">
                      <b>HP. {{ formDetail.hp }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Alamat
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.alamat }}</b></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Kabupaten/Kota
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.kabupaten.name || formDetail.kabupaten_kota_id }}</b></div>
            </div>
          </div>
          <!-- <div class="col-12 col-lg-6">
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Kas Awal
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.kas_awal }}</b></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 col-lg-4">
                Kas Berjalan
              </div>
              <div class="col-6 col-lg-8">: <b>{{ formDetail.cash_in_hand_berjalan }}</b></div>
            </div>
          </div> -->
        </div>
        <div class="mt-3">
          <h5>User Cabang</h5>
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nama</th>
                <th scope="col">Divisi</th>
                <th scope="col">Jabatan</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="user.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ user.name || "-" }}</td>
                <td>{{ user.nama_divisi || "-" }}</td>
                <td>{{ user.role_name || "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4 text-right">
          <b-button class="ml-2" variant="danger" @click="hideModal">Tutup</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => {
    return {
      initStatus: 0,
      initStatusDetail: 0,
      isBusy: true,
      kacab: [],
      props: {
        value: {
          type: [String, Number],
          default: "",
        },
      },
      filter: {
        keyword: null,
      },
      form: {
        kode: null,
        name: null,
        hp: null,
        telepon: null,
        alamat: null,
        kabupaten_kota_id: null,
        is_active: 1,
        old_id: null,
        kas_awal: null,
        cash_in_hand_berjalan: null,
        skema_hitung: null,
        counter_anggota: null,
        counter_faktur: null,
        kepala_cabang_id: null,
        kepala_cabang_senior_id: null,
        kepala_unit_id: null,
        created_by_id: null,
        updated_by_id: null,
        ip_address: null,
        latitude: null,
        longitude: null,
      },
      formEdit: {
        id: null,
        kode: null,
        name: null,
        hp: null,
        telepon: null,
        alamat: null,
        kabupaten_kota_id: null,
        is_active: 1,
        old_id: null,
        kas_awal: null,
        cash_in_hand_berjalan: null,
        skema_hitung: null,
        counter_anggota: null,
        counter_faktur: null,
        kepala_cabang_id: null,
        kepala_cabang_senior_id: null,
        kepala_unit_id: null,
        created_by_id: null,
        updated_by_id: null,
        ip_address: null,
        latitude: null,
        longitude: null,
      },
      formDetail: {
        kode: null,
        name: null,
        hp: null,
        telepon: null,
        alamat: null,
        kabupaten_kota_id: null,
        is_active: null,
        old_id: null,
        kas_awal: null,
        cash_in_hand_berjalan: null,
        skema_hitung: null,
        counter_anggota: null,
        counter_faktur: null,
        kepala_cabang_id: null,
        kepala_cabang_senior_id: null,
        kepala_unit_id: null,
        created_by_id: null,
        updated_by_id: null,
        ip_address: null,
        latitude: null,
        longitude: null,
      },
      fields: [
        {
          key: "kode",
          label: "Kode",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "name",
          label: "Cabang",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "telepon",
          label: "Telepon",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        { key: "alamat", label: "Alamat" },
        {
          key: "is_active",
          label: "Status",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      users: [],
      cabang: [],
      optionsAllKcs: [],
      optionsKabupaten: [],
      cabangId: null,
      isModalVisible: false,
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      query: "",
    };
  },
  validations: {
    form: {
      kode: {
        required,
      },
      name: {
        required,
      },
      telepon: {
        required,
      },
      hp: {
        required,
      },
      alamat: {
        required,
      },
      kabupaten_kota_id: {
        required,
      },
      kas_awal: {
        required,
      },
      cash_in_hand_berjalan: {
        required,
      },
      ip_address: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
    },
    formEdit: {
      kode: {
        required,
      },
      name: {
        required,
      },
      telepon: {
        required,
      },
      hp: {
        required,
      },
      alamat: {
        required,
      },
      kabupaten_kota_id: {
        required,
      },
      kas_awal: {
        required,
      },
      cash_in_hand_berjalan: {
        required,
      },
      ip_address: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
    },
  },

  //----------------- Init Function -----------------//
  methods: {
    init: function () {
      this.initStatus = 0;
      let cabang = "";
      // Get Cabang
      cabang = this.$axios.get("api/admin/cabang").then((response) => {
        let datas = response.data.data;

        for (var data of datas.data) {
          this.cabang.push({
            id: data.id,
            kode: data.kode,
            name: data.name,
            telepon: data.telepon,
            alamat: data.alamat,
            is_active: data.is_active,
            hp: data.hp,
            kabupaten_kota_id: data.kabupaten_kota_id,
            kas_awal: data.kas_awal,
            cash_in_hand_berjalan: data.cash_in_hand_berjalan,
            kepala_unit_id: data.kepala_unit_id,
            kepala_cabang_senior_id: data.kepala_cabang_senior_id,
            kepala_cabang_id: data.kepala_cabang_id,
            ip_address: data.ip_address,
            latitude: data.latitude,
            longitude: data.longitude,
          });
        }

        this.perPage = datas.per_page;
        this.size = this.perPage;
        this.from = datas.from;
        this.limit = datas.to;

        this.updateTotalItem(datas.total);
      });
      // get kabupaten
      cabang = this.$axios
        .get("api/admin/wilayah/kabupaten")
        .then((response) => {
          let datas = response.data.data;
          for (let wilayah of datas) {
            this.optionsKabupaten.push({
              id: wilayah.id,
              name: wilayah.name,
              text: wilayah.name,
            });
          }
        });

      // Get KCS
      cabang = this.$axios
        .get("api/admin/cabang-user/user-kcs")
        .then((response) => {
          let datas = response.data.data;
          for (let kcs of datas) {
            this.optionsAllKcs.push({
              id: kcs.id,
              name: kcs.name,
            });
          }
        });

      Promise.all([cabang])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error)
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    handleRedirectEdit(value) {
      this.$router.push(`/master-cabang/cabang-edit?id=${value}`)
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange: function () {
      if (!this.loading) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getCabang();
      }
    },
    handleChangeSearch: function (e) {
      const keyword = e.target.value;

      if (keyword.length > 1) {
        if (!this.loading) {
          this.query = "?search=" + keyword;
          this.getCabang();
        }

      }
    },

    // ======== //
    searchUserKanit(value) {
      if (value.length > 2) {
        this.$store.dispatch("searchUser", { search: value })
      }
    },
    searchUserKacab(value) {
      if (value.length > 2) {
        this.$store.dispatch("searchUser", { search: value })
      }
    },
    searchUserKacabSenior(value) {
      if (value.length > 2) {
        this.$store.dispatch("searchUser", { search: value })
      }
    },
    selectUserKanit(value) {
      this.formEdit.kepala_unit_id = value.id

      console.log(value.id, "value.id")
    },
    selectUserKacab(value) {
      this.formEdit.kepala_cabang_id = value.id

      console.log(value.id, "value.id")
    },
    selectUserKacabSenior(value) {
      this.formEdit.kepala_cabang_senior_id = value.id

      console.log(value.id, "value.id")
    },
    // ======== //

    onReset() {
      this.query = ""
      this.filter.keyword = ""
      this.getCabang()
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;
      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    //-----------------  Callback Function -----------------//
    getCabang: function () {
      this.toggleBusy();
      this.$axios
        .get(`api/admin/cabang` + this.query)
        .then((response) => {
          let datas = response.data.data;
          this.cabang = [];
          this.toggleBusy();
          for (var data of datas.data) {
            this.cabang.push({
              id: data.id,
              kode: data.kode,
              name: data.name,
              telepon: data.telepon,
              alamat: data.alamat,
              is_active: data.is_active,
              hp: data.hp,
              kabupaten_kota_id: data.kabupaten_kota_id,
              kas_awal: data.kas_awal,
              cash_in_hand_berjalan: data.cash_in_hand_berjalan,
              kepala_unit_id: data.kepala_unit_id,
              kepala_cabang_senior_id: data.kepala_cabang_senior_id,
              kepala_cabang_id: data.kepala_cabang_id,
              ip_address: data.ip_address,
              latitude: data.latitude,
              longitude: data.longitude,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);

          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        kode: null,
        name: null,
        hp: null,
        telepon: null,
        alamat: null,
        kabupaten_kota_id: null,
        is_active: 1,
        old_id: null,
        kas_awal: null,
        cash_in_hand_berjalan: null,
        skema_hitung: null,
        counter_anggota: null,
        counter_faktur: null,
        kepala_cabang_id: null,
        kepala_cabang_senior_id: null,
        kepala_unit_id: null,
        created_by_id: null,
        updated_by_id: null,
        ip_address: null,
        latitude: null,
        longitude: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-cabang"].hide();
      this.resetForm();
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else if (this.form.name != null) {
        const form = this.form;
        const formCreate = {
          is_active: 1,
          old_id: null,
          updated_at: new Date().toISOString(),
          name: form.name,
          alamat: form.alamat,
          cash_in_hand_berjalan: form.cash_in_hand_berjalan,
          counter_anggota: 1,
          counter_faktur: 1,
          hp: form.hp,
          kode: form.kode,
          skema_hitung: 0,
          telepon: form.telepon,
          created_by_id: localStorage.getItem("id"),
          updated_by_id: localStorage.getItem("id"),
          kabupaten_kota_id: form.kabupaten_kota_id ? form.kabupaten_kota_id.id : null,
          kepala_cabang_id: form.kepala_cabang_id ? form.kepala_cabang_id.id : null,
          kepala_cabang_senior_id: form.kepala_cabang_id ? form.kepala_cabang_id.id : null,
          kepala_unit_id: form.kepala_unit_id ? form.kepala_unit_id.id : null,
          kas_awal: form.kas_awal,
          ip_address: form.ip_address,
          latitude: form.latitude,
          longitude: form.longitude
        }
        this.showSweetAlertCreate(formCreate);
        this.$bvModal.hide("m-create-cabang");
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      if (this.$v.formEdit.$anyError) {
        return;
      } else if (this.formEdit.name != null) {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-cabang");
      }
    },

    //-----------------  Create Function -----------------//
    showSweetAlertCreate: function (formCreate) {
      var namaCabang = this.form.name;

      var content = document.createElement("div");
      content.innerHTML =
        "<p style='text-align: left;'>Apakah anda yakin menambahkan data sebagai berikut: \n</p>" +
        "<div style='width: 100%; text-align: left; margin-top: 10px'>" +
        "<table class='small'>" +
        "<tr>" +
        "<td>Divisi</td>" +
        "<td style='width: 10%'>:</td>" +
        "<td>" +
        "<strong>" +
        namaCabang +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</div>";

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSubmitting = true;
          this.$axios
            .post("/api/admin/cabang", formCreate)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Cabang telah ditambahkan.",
                  iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                  iconColor: '#a5dc86',
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Cabang gagal ditambahkan.",
                  iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                  iconColor: '#dc3545',
                  showConfirmButton: false,
                  timer: 2000,
                });
              }

              this.form = {};
              this.getCabang();
            })
            .catch((error) => {
              // this.$helper.parseError(this, error)
              console.log(error)
            })
            .finally(() => (this.isSubmitting = false));
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });

          this.resetForm();
        }
      });
    },

    onDetailCabang: function (id) {
      this.cabangId = id;
      this.showModalDetail();
    },

    //-----------------  Detail Function -----------------//
    showModalDetail: function () {
      this.initStatusDetail = 0;
      let cabang_id = this.cabangId;
      let cabangDetail = this.$axios
        .get("api/admin/cabang/" + cabang_id)
        .then((response) => {
          let datas = response.data.data;
          this.formDetail.is_active = datas.is_active;
          this.formDetail.kode = datas.kode;
          this.formDetail.name = datas.name;
          this.formDetail.telepon = datas.telepon;
          this.formDetail.hp = datas.hp;
          this.formDetail.alamat = datas.alamat;
          this.formDetail.kabupaten_kota_id = datas.kabupaten_kota_id;
          this.formDetail.kas_awal = this.$helper.rupiah(datas.kas_awal);
          this.formDetail.cash_in_hand_berjalan = this.$helper.rupiah(datas.cash_in_hand_berjalan);
          this.formDetail.kepala_unit_id = datas.kepala_unit_id;
          this.formDetail.kepala_cabang_senior_id =
            datas.kepala_cabang_senior_id;
          this.formDetail.kepala_cabang_id = datas.kepala_cabang_id;
          this.formDetail.ip_address = datas.ip_address;
          this.formDetail.latitude = datas.latitude;
          this.formDetail.longitude = datas.longitude;
          this.users = datas.user_cabang

          const kabupaten_text = this.optionsKabupaten.filter(item => {
            return item.id === datas.kabupaten_kota_id
          })
          this.formDetail.kabupaten = kabupaten_text[0];
        });

      Promise.all([cabangDetail])
        .then(() => {
          this.initStatusDetail = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatusDetail = -1;
        });

      this.$bvModal.show("m-detail-cabang");
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (id) {
      // get exist data cabang
      this.$axios.get("api/admin/cabang/" + id).then((response) => {
        let datas = response.data.data;

        // datas.user_cabang.map(i => {
        //   if (i.role_name === "Kepala Unit") {
        //     this.formEdit.kepala_unit_id = i.id || "";
        //   }
        //   if (i.role_name === "Kepala Cabang") {
        //     this.formEdit.kepala_cabang_id = i.id || "";
        //   }
        //   if (i.role_name === "Kepala Cabang Senior") {
        //     this.formEdit.kepala_cabang_senior_id = i.id || "";
        //   }
        // })


        this.formEdit.id = datas.id;
        this.formEdit.is_active = datas.is_active;
        this.formEdit.kode = datas.kode;
        this.formEdit.name = datas.name;
        this.formEdit.telepon = datas.telepon;
        this.formEdit.hp = datas.hp;
        this.formEdit.alamat = datas.alamat;
        this.formEdit.kabupaten_kota_id = datas.kabupaten_kota_id;
        this.formEdit.kas_awal = datas.kas_awal;
        this.formEdit.cash_in_hand_berjalan = datas.cash_in_hand_berjalan;
        this.formEdit.kepala_unit_id = datas.kepala_unit_id;
        this.formEdit.kepala_cabang_senior_id = datas.kepala_cabang_senior_id;
        this.formEdit.kepala_cabang_id = datas.kepala_cabang_id;
        this.formEdit.ip_address = datas.ip_address;
        this.formEdit.latitude = datas.latitude;
        this.formEdit.longitude = datas.longitude;
        this.users = datas.user_cabang;
      });
      this.$bvModal.show("m-edit-cabang");
    },

    showSweetAlertEdit: function () {

      // console.log(this.formEdit.kepala_cabang_id, "form edit")
      // return;



      var namaCabang = this.formEdit.name;

      var content = document.createElement("div");
      content.innerHTML =
        "<p style='text-align: left;'>Apakah anda yakin melakukan perubahan pada data berikut: \n</p>" +
        "<div style='width: 100%; text-align: left; margin-top: 10px'>" +
        "<table class='small'>" +
        "<tr>" +
        "<td>Divisi</td>" +
        "<td style='width: 5%'>:</td>" +
        "<td>" +
        "<strong>" +
        namaCabang +
        "</strong>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</div>";

      this.$swal({
        title: "Konfirmasi",
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let formEdit = {
            id: this.formEdit.id,
            kode: this.formEdit.kode,
            name: this.formEdit.name,
            telepon: this.formEdit.telepon,
            alamat: this.formEdit.alamat,
            is_active: this.formEdit.is_active,
            hp: this.formEdit.hp,
            kabupaten_kota_id: this.formEdit.kabupaten_kota_id,
            kas_awal: this.formEdit.kas_awal,
            cash_in_hand_berjalan: this.formEdit.cash_in_hand_berjalan,
            kepala_unit_id: this.formEdit.kepala_unit_id,
            kepala_cabang_senior_id: this.formEdit.kepala_cabang_senior_id,
            kepala_cabang_id: this.formEdit.kepala_cabang_id,
            ip_address: this.formEdit.ip_address,
            latitude: this.formEdit.latitude,
            longitude: this.formEdit.longitude,
            old_id: null,
            skema_hitung: null,
            counter_anggota: null,
            counter_faktur: null,
            created_by_id: null,
            updated_by_id: null,
          };

          this.isSubmitting = true;
          this.$axios
            .post("/api/admin/cabang/" + this.formEdit.id, formEdit)
            .then((response) => {
              this.$bvModal.hide("m-edit-cabang");

              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Perubahan cabang berhasil.",
                  iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
                  iconColor: '#a5dc86',
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Perubahan cabang gagal.",
                  iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                  iconColor: '#dc3545',
                  showConfirmButton: false,
                  timer: 2000,
                });
              }

              this.form = {};
              this.getCabang();
            })
            .catch((error) => this.$helper.parseError(this, error))
            .finally(() => (this.isSubmitting = false));
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
  },
  computed: {
    kabupatens() {
      return this.$store.getters.kabupatenLists;
    },
    resultUsers() {
      return this.$store.getters.resultUsers;
    },
  },

  //-----------------  Initial Function -----------------//
  created: function () {
    this.init();
    this.$store.dispatch("getKabupaten")
  },
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: #f79483;
}

.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}
</style>
